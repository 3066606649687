import Vue from 'vue'
import components from './lib'

const options = JSON.parse('{"cacheHeader":"x-cache","pages":["/"],"prefix":"mp:cache:www.runnea.it:202504071744030117:nuxt","hash":"content","ttl":3600,"ttls":[[{},1],[{},1]],"redis":{"port":6379,"host":"localhost"}}')

for (const name in components) {
  Vue.component(name, {
    extends: components[name],
    props: {
      options: {
        type: Object,
        default: () => ({ ...options })
      }
    }
  })
}
